/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import useStyles from '@common_buttonqty/style';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import React from 'react';

const ButtonQty = ({
    value = 1, onChange, max = 100, disabled = false, isSetLocalValue = true,
}) => {
    const styles = useStyles();
    const [localValue, setLocalValue] = React.useState(value);

    React.useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleMinus = () => {
        if (!disabled && localValue > 1 && localValue <= max) {
            if (onChange) {
                onChange(localValue - 1);
            }
            if (isSetLocalValue) {
                setLocalValue(parseInt(localValue, 0) - 1);
            }
        }
    };
    const handlePlus = () => {
        if (!disabled && localValue > 0 && localValue < max) {
            if (onChange) {
                onChange(localValue + 1);
            }
            if (isSetLocalValue) {
                setLocalValue(parseInt(localValue, 0) + 1);
            }
        }
    };

    const handleLocalChange = (event) => {
        const val = event.target.value ? parseInt(event.target.value, 0) : 0;
        if (val < 1) {
            window.toastMessage({
                open: true,
                text: 'Min input 1',
                variant: 'error',
            });
        } else if (val > max) {
            window.toastMessage({
                open: true,
                text: `Max input ${max}`,
                variant: 'error',
            });
        } else {
            if (onChange) {
                onChange(val);
            }
            setLocalValue(parseInt(val, 0));
        }
    };
    const disabledMin = disabled || localValue === 1;
    const disableMax = disabled || localValue === max;

    return (
        <div className={styles.box}>
            <div className={classNames(styles.minus, disabledMin ? styles.disabled : '')} onClick={handleMinus}>
                -
            </div>
            <input
                disabled={disabled}
                value={localValue}
                className={classNames(styles.input, 'common-valueQty-input')}
                type="number"
                onChange={(e) => {
                    handleLocalChange(e);
                    const userDataCookies = Cookies.get('cdt');
                    const cartIdCoookies = Cookies.get('nci');
                    const userData = userDataCookies ? JSON.parse(userDataCookies) : {};
                    const cartId = cartIdCoookies || {};
                    // eslint-disable-next-line no-undef
                    smartech('dispatch', 'op_productmodal_qty_input', {
                        email: `${userData.email}`,
                        wp_code: `${userData.wp_code}`,
                        qty: `${e.target.value}`,
                        cart_id: `${cartId}`,
                    });
                }}
            />
            <div className={classNames(styles.plus, disableMax ? styles.disabled : '')} onClick={handlePlus}>
                +
            </div>
        </div>
    );
};

export default ButtonQty;
