/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
import Breadcrumb from '@common_breadcrumb';
import Typography from '@common_typography';
import { modules } from '@config';
// import ListReviews from '@core_modules/product/pages/default/components/ListReviews';
import ModalPopupImage from '@core_modules/product/pages/default/components/ModalPopupImage';
import OptionItem from '@core_modules/product/pages/default/components/OptionItem';
import SharePopup from '@core_modules/product/pages/default/components/SharePopup';
import useStyles from '@core_modules/product/pages/default/components/style';
import { getProductBannerLite } from '@core_modules/product/services/graphql';
import { getHost } from '@helper_config';
import { formatPrice } from '@helper_currency';
import { breakPointsUp } from '@helper_theme';
// import Avatar from '@material-ui/core/Avatar';
import { getIconByStore } from '@core_modules/catalog/helpers/iconStore';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import classNames from 'classnames';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React from 'react';

const Button = dynamic(() => import('@common_button'), { ssr: true });
const Banner = dynamic(() => import('@common_productslider/BannerThumbnail'), { ssr: true });
const DesktopOptions = dynamic(() => import('@core_modules/product/pages/default/components/OptionItem/DesktopOptions'), { ssr: true });
const ExpandDetail = dynamic(() => import('@core_modules/product/pages/default/components/ExpandDetail'), { ssr: false });
// const TabsView = dynamic(() => import('@core_modules/product/pages/default/components/DesktopTabs'), { ssr: false });
const PriceFormat = dynamic(() => import('@common_priceformat'), { ssr: true });
// const RatingStar = dynamic(() => import('@common_ratingstar'), { ssr: true });
// const ItemShare = dynamic(() => import('@core_modules/product/pages/default/components/SharePopup/item'), { ssr: false });
const WeltpixelLabel = dynamic(() => import('@plugin_productitem/components/WeltpixelLabel'), { ssr: false });
const UpsellDrawer = dynamic(() => import('@core_modules/product/pages/default/components/RightDrawer'), { ssr: false });
const RelatedProductCaraousel = dynamic(() => import('@core_modules/product/pages/default/components/RelatedProductCaraousel'), { ssr: false });
const PromoBannersLite = dynamic(() => import('@core_modules/product/pages/default/components/PromoBannersLite'), { ssr: false });

const ProductPage = (props) => {
    const styles = useStyles();
    const {
        t,
        data,
        openOption,
        handleOption,
        setOpenOption,
        setBanner,
        setPrice,
        openShare,
        setOpenShare,
        route,
        banner,
        openDrawer,
        setOpenDrawer,
        breadcrumbsData,
        price,
        handleWishlist,
        // reviewValue,
        wishlist,
        expandData,
        openImageDetail,
        handleOpenImageDetail,
        stockStatus,
        additionalPrice,
        smartProductTabs,
        isLogin,
        handleSetCompareList,
        enablePopupImage,
        enableMultiSeller,
        storeConfig,
        dataSeller,
        configurableOptionsTierPrice,
        setConfigurableOptionsTierPrice,
    } = props;
    const desktop = breakPointsUp('sm');

    const context = isLogin && isLogin === 1 ? { request: 'internal' } : {};
    const [getBannerLite, bannerLiteResult] = getProductBannerLite(route.asPath.slice(1), { context });

    let citySplit;
    if (enableMultiSeller && dataSeller && dataSeller.length > 0) {
        citySplit = dataSeller[0].city?.split(',');
    }

    React.useEffect(() => {
        getBannerLite();
    }, [bannerLiteResult.called]);

    let bannerLiteData = [];
    if (
        bannerLiteResult
        && bannerLiteResult.data
        && bannerLiteResult.data.products.items
        && bannerLiteResult.data.products.items.length > 0
        && bannerLiteResult.data.products.items[0].banners_data
    ) {
        bannerLiteData = bannerLiteResult.data.products.items[0].banners_data;
    }
    const bannerLiteObj = {
        top: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '0') || [],
        after: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '1') || [],
        label: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '2') || [],
    };

    const getClassByStatus = (status) => {
        if (status === 'WPD') {
            return styles.verified;
        }
        if (status === 'Grosir') {
            return styles.grospin;
        }
        return styles.officialDistributor;
    };

    // eslint-disable-next-line consistent-return
    const tierPriceConfigurable = (dataTierPrice) => {
        if (dataTierPrice.length > 0) {
            // eslint-disable-next-line consistent-return
            return dataTierPrice.map((uomTiers) => {
                if (uomTiers.tier_prices_custom.length > 0) {
                    return uomTiers.tier_prices_custom.map((tiers, index) => {
                        const priceTiers = {
                            quantity: tiers.quantity,
                            price: formatPrice(tiers.final_price.value),
                            uom: uomTiers.uom,
                            discount: Math.round(
                                ((tiers.final_price.value - (tiers.discount.amount_off + tiers.final_price.value))
                                    / (tiers.discount.amount_off + tiers.final_price.value))
                                    * -100,
                            ),
                        };
                        return (
                            <Typography variant="p" type="regular" key={index} className={styles.tierPriceCustom}>
                                {t('product:priceTiersCustomConfigurable', { priceTiers })}
                            </Typography>
                        );
                    });
                }
            });
        }
    };

    const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <FavoriteBorderOutlined className={styles.iconShare} />;
    return (
        <>
            <div className="hidden-mobile">
                <UpsellDrawer
                    open={openDrawer}
                    setOpen={() => setOpenDrawer(!openDrawer)}
                    t={t}
                    dataProduct={data}
                    isLogin={isLogin}
                    storeConfig={storeConfig}
                />
                {enablePopupImage && (
                    <ModalPopupImage open={openImageDetail} setOpen={handleOpenImageDetail} banner={banner} storeConfig={storeConfig} />
                )}
            </div>
            <OptionItem
                {...props}
                open={openOption}
                setOpen={() => setOpenOption(!openOption)}
                setBanner={setBanner}
                setPrice={setPrice}
                setConfigurableOptionsTierPrice={setConfigurableOptionsTierPrice}
            />
            <SharePopup open={openShare} setOpen={() => setOpenShare(!openShare)} link={getHost() + route.asPath} {...props} />
            <div className={classNames(styles.container, 'row')}>
                <div className="col-lg-12 hidden-mobile hidden-desktop">
                    <Breadcrumb data={breadcrumbsData} variant="text" />
                </div>

                {bannerLiteObj.top
                    && bannerLiteObj.top.length > 0
                    && bannerLiteObj.top.map((topBanner) => (
                        <PromoBannersLite
                            type="top"
                            key={topBanner.entity_id}
                            classes={classNames(styles.bannerLiteTop, 'col-xs-12')}
                            src={topBanner.banner_link}
                            imgSrc={topBanner.banner_image}
                            alt={topBanner.banner_alt}
                            storeConfig={storeConfig}
                        />
                    ))}

                <div className={classNames(styles.headContainer, 'col-xs-12')}>
                    {bannerLiteObj.top
                        && bannerLiteObj.top.length > 0
                        && bannerLiteObj.top.map((topBanner) => (
                            <PromoBannersLite
                                type="top"
                                key={topBanner.entity_id}
                                classes={classNames(styles.bannerLiteTopMobile, 'col-xs-12')}
                                src={topBanner.banner_link}
                                imgSrc={topBanner.banner_image}
                                alt={topBanner.banner_alt}
                                storeConfig={storeConfig}
                            />
                        ))}
                    <div className="row">
                        {bannerLiteObj.label
                            && bannerLiteObj.label.length > 0
                            && bannerLiteObj.label.map((labelBanner) => (
                                <PromoBannersLite
                                    type="label"
                                    key={labelBanner.entity_id}
                                    classes={classNames(styles.bannerLiteLabel, 'col-xs-12')}
                                    imgSrc={labelBanner.banner_image}
                                    alt={labelBanner.banner_alt}
                                    storeConfig={storeConfig}
                                />
                            ))}
                    </div>
                    <Banner
                        data={banner}
                        noLink
                        thumbnail
                        showArrow
                        contentWidth="auto"
                        autoPlay={false}
                        width={960}
                        height={1120}
                        actionImage={desktop && enablePopupImage ? handleOpenImageDetail : () => {}}
                        customProduct={styles.bannerProduct}
                        storeConfig={storeConfig}
                    >
                        {storeConfig?.pwa?.label_enable && storeConfig?.pwa?.label_weltpixel_enable && (
                            <WeltpixelLabel t={t} weltpixel_labels={data.weltpixel_labels || []} categoryLabel={false} withThumbnailProduct />
                        )}
                    </Banner>
                    <div className="hidden-desktop">
                        <UpsellDrawer open={openDrawer} setOpen={() => setOpenDrawer(!openDrawer)} t={t} dataProduct={data} isLogin={isLogin} />
                    </div>
                </div>
                <div className={classNames(styles.body, 'col-xs-12')}>
                    <div className={classNames(styles.titleContainer, 'hidden-desktop hidden-mobile')}>
                        <Breadcrumb data={breadcrumbsData} variant="text" />
                    </div>

                    <div className={styles.titleContainer}>
                        <div className={styles.titlePriceContainer}>
                            <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                {data.name}
                            </Typography>
                            <PriceFormat {...price} additionalPrice={additionalPrice} />
                        </div>
                        <div className={styles.shareContainer}>
                            {modules.productcompare.enabled && (
                                <div className="hidden-desktop hidden-mobile">
                                    <IconButton className={styles.btnShare} onClick={() => handleSetCompareList(data.id)}>
                                        <CompareArrowsIcon color="primary" />
                                    </IconButton>
                                </div>
                            )}
                            <IconButton className={classNames(styles.btnShare, 'hidden-desktop hidden-mobile')} onClick={handleWishlist}>
                                {favoritIcon}
                            </IconButton>
                            <div className="hidden-desktop">
                                <IconButton
                                    className={classNames(styles.btnShare, 'hidden-desktop hidden-mobile')}
                                    onClick={() => setOpenShare(true)}
                                >
                                    <ShareOutlined className={styles.iconShare} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className={styles.titleContainer}>
                        <div className={classNames('row', styles.sku)}>
                            <Typography className="clear-margin-padding" variant="p" type="regular" letter="capitalize">
                                SKU#:
                                {' '}
                            </Typography>
                            <Typography variant="p" type="bold" letter="none">
                                {data.sku || ''}
                            </Typography>
                        </div>
                        <Typography variant="p" type="bold" letter="uppercase">
                            {stockStatus.replace(/_/g, ' ') || ''}
                        </Typography>
                    </div>

                    {/* <div className={styles.titleContainer}>
                        <div className={styles.ratingContainer}>
                            <RatingStar value={reviewValue || 0} />
                            <Typography variant="p" type="regular" letter="capitalize">
                                {data.review.reviews_count || 0}
                                {' '}
                                {t('product:review')}
                            </Typography>
                        </div>
                    </div> */}
                    {enableMultiSeller && dataSeller && dataSeller.length > 0 ? (
                        <div className={styles.titleContainer}>
                            <div className={styles.sellerContainer}>
                                {/* <Link href={`/seller/${dataSeller[0].id}`}> */}
                                <div className={styles.viewsellerCompany}>
                                    <div className={styles.sellerCompany}>
                                        <picture>
                                            <img
                                                src={getIconByStore(data.seller.seller_type)}
                                                alt={dataSeller[0].name}
                                                className={styles.iconSeller}
                                            />
                                        </picture>
                                        <Typography
                                            variant="p"
                                            className={clsx(getClassByStatus(data.seller.seller_type), styles.productCompany)}
                                            color="default"
                                            letter="capitalize"
                                        >
                                            {dataSeller[0].name}
                                            {' '}
                                            (
                                            {citySplit[0]}
                                            )
                                        </Typography>
                                    </div>
                                    <Link href={`/seller/${dataSeller[0].id}`} style={{ textDecoration: 'none' }}>
                                        <Button className={styles.btnVisit}>Kunjungi Toko</Button>
                                    </Link>
                                </div>
                                {/* <div>
                                        <img src={getIconByStore(dataSeller[0].name)} alt={dataSeller[0].name} className={styles.iconSeller} />
                                        <Typography variant="p" type="bold" letter="capitalize" size="14">
                                            {dataSeller[0].name}
                                        </Typography>
                                        <Typography variant="p" type="regular" letter="capitalize" size="14">
                                            {citySplit[0]}
                                        </Typography>
                                    </div> */}
                            </div>
                        </div>
                    ) : null}

                    <div className={styles.titleContainer}>
                        <div className={styles.priceTiersContainer}>
                            {price.priceTiers.length > 0
                                && price.priceTiers.map((tiers, index) => {
                                    const priceTiers = {
                                        quantity: tiers.quantity,
                                        currency: tiers.final_price.currency,
                                        price: formatPrice(tiers.final_price.value),
                                        discount: tiers.discount.percent_off,
                                    };
                                    return (
                                        <Typography variant="p" type="regular" key={index}>
                                            {t('product:priceTiers', { priceTiers })}
                                        </Typography>
                                    );
                                })}
                            {price.priceTiersCustom
                                && price.priceTiersCustom.length > 0
                                && price.priceTiersCustom.map((tiers, index) => {
                                    const priceTiers = {
                                        quantity: tiers.quantity,
                                        price: formatPrice(tiers.final_price.value),
                                        discount: Math.round(
                                            ((tiers.final_price.value - (tiers.discount.amount_off + tiers.final_price.value))
                                                / (tiers.discount.amount_off + tiers.final_price.value))
                                                * -100,
                                        ),
                                    };
                                    return (
                                        <Typography variant="p" type="regular" key={index} className={styles.tierPriceCustom}>
                                            {t('product:priceTiersCustom', { priceTiers })}
                                        </Typography>
                                    );
                                })}
                            <Typography variant="p" type="regular">
                                {configurableOptionsTierPrice.length > 0 && tierPriceConfigurable(configurableOptionsTierPrice)}
                            </Typography>
                        </div>
                    </div>

                    <div className="row">
                        {storeConfig?.pwa?.label_enable && storeConfig?.pwa?.label_weltpixel_enable && (
                            <WeltpixelLabel t={t} weltpixel_labels={data.weltpixel_labels || []} categoryLabel={false} onDetailProduct />
                        )}
                    </div>

                    <div className="hidden-desktop">
                        {data.short_description.html !== '' && (
                            <div className={styles.desc}>
                                <Typography variant="span" type="regular" size="10">
                                    <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} />
                                </Typography>
                            </div>
                        )}
                        <div>
                            <ExpandDetail data={expandData} smartProductTabs={smartProductTabs} />
                        </div>
                        <div className="row">
                            {bannerLiteObj.after
                                && bannerLiteObj.after.length > 0
                                && bannerLiteObj.after.map((afterBanner) => (
                                    <PromoBannersLite
                                        type="after"
                                        key={afterBanner.entity_id}
                                        classes={classNames(styles.bannerLiteAfter, 'col-xs-6')}
                                        src={bannerLiteObj.after.banner_link}
                                        imgSrc={afterBanner.banner_image}
                                        alt={afterBanner.banner_alt}
                                        storeConfig={storeConfig}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="hidden-mobile">
                        <DesktopOptions
                            {...props}
                            setOpen={setOpenOption}
                            setBanner={setBanner}
                            setPrice={setPrice}
                            configurableOptionsTierPrice={configurableOptionsTierPrice}
                            setConfigurableOptionsTierPrice={setConfigurableOptionsTierPrice}
                        />

                        <div className="row">
                            {bannerLiteObj.after
                                && bannerLiteObj.after.length > 0
                                && bannerLiteObj.after.map((afterBanner) => (
                                    <PromoBannersLite
                                        type="after"
                                        key={afterBanner.entity_id}
                                        classes={classNames(styles.bannerLiteAfter, 'col-xs-6')}
                                        src={bannerLiteObj.after.banner_link}
                                        imgSrc={afterBanner.banner_image}
                                        alt={afterBanner.banner_alt}
                                        storeConfig={storeConfig}
                                    />
                                ))}
                        </div>

                        <div className={classNames(styles.desktopShareIcon, 'hidden-desktop hidden-mobile')}>
                            <Typography className={classNames(styles.shareTitle, 'hidden-desktop hidden-mobile')} variant="title">
                                {t('product:shareTitle')}
                            </Typography>
                            <div className={modules.productcompare.enabled && styles.rowItem}>
                                {/* <ItemShare link={getHost() + route.asPath} /> */}
                                {modules.productcompare.enabled && (
                                    <Button
                                        className={classNames(styles.btnCompare, 'hidden-desktop hidden-mobile')}
                                        color="primary"
                                        onClick={() => handleSetCompareList(data.id)}
                                    >
                                        <CompareArrowsIcon color="inherit" style={{ fontSize: '18px' }} />
                                        <Typography variant="p" align="center" letter="uppercase">
                                            Compare
                                        </Typography>
                                    </Button>
                                )}
                            </div>
                        </div>
                        {data.short_description.html !== '' && (
                            <div className={styles.desc}>
                                <Typography variant="span" type="regular" size="10">
                                    <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} />
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                {/* <div className="hidden-desktop">
                    <ListReviews {...props} />
                </div>
                <div className={classNames(styles.tabs, 'col-xs-12 col-lg-12 hidden-mobile')}>
                    <TabsView
                        {...props}
                        dataInfo={expandData}
                        smartProductTabs={smartProductTabs || {
                            tab_2: {
                                label: null,
                                content: null,
                            },
                        }}
                    />
                </div> */}
                <RelatedProductCaraousel t={t} dataProduct={data} isLogin={isLogin} storeConfig={storeConfig} />
                <div className={classNames(styles.footer, 'hidden-desktop')}>
                    <Button
                        className={styles.btnAddToCard}
                        color="primary"
                        onClick={handleOption}
                        disabled={data && data.stock_status === 'OUT_STOCK'}
                    >
                        <Typography variant="span" align="center" type="bold" letter="uppercase" color="white">
                            {t('product:addToCart')}
                        </Typography>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProductPage;
