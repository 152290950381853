import Header from '@common_headermobile';
import ShoppingBagIcon from '@plugin_shoppingbag';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        zIndex: 7,
        background: 'none',
        [theme.breakpoints.up('lg')]: {
            width: '500px !important',
            margin: '0px auto !important',
        },
        [theme.breakpoints.up('md')]: {
            width: '500px !important',
            margin: '0px auto !important',
        },
        [theme.breakpoints.down('sm')]: {
            top: '5%',
            left: '50%',
            width: 500,
            margin: '0px auto !important',
            transform: 'translate(-50%, -50%)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: '0px auto !important',
        },
    },
}));

const CustomHeader = (props) => {
    const styles = useStyles();
    const { storeConfig } = props;
    return (
        <Header
            RightComponent={(
                <ShoppingBagIcon withLink storeConfig={storeConfig} />
            )}
            className={styles.container}
            {...props}
        />
    );
};

export default CustomHeader;
