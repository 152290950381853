import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK, RED, WHITE } from '@theme_color';
import {
    CreateMargin,
    // CenterAbsolute,
    FlexColumn,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    btnAddToCardContainer: {
        position: 'relative',
        right: '0%',
        // [theme.breakpoints.up('xmed')]: {
        //     top: '-2rem',
        //     right: '-75%',
        // },
        // [theme.breakpoints.up('xm')]: {
        //     top: '-2rem',
        //     right: '-115%',
        // },
    },
    btnAddToCard: {
        // [theme.breakpoints.down('sm')]: {
        //     ...CenterAbsolute,
        //     borderRadius: 100,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: 316,
        //     float: 'left',
        //     maxWidth: '95%',
        //     borderRadius: 100,
        //     ...CenterAbsolute,
        // },
        // ...CreateMargin(0, 8, 0, 0),
        // width: '100%',
        // height: 41,
        // bottom: 0,
        // left: 0,
        // opacity: 'none',
        width: '100%',
        textTransform: 'none',
        color: `${BLACK} !important`,
        // borderRadius: 100,
        '& span': {
            color: `${BLACK} !important`,
            textTransform: 'none',
            fontWeight: '600',
        },
    },
    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },
    error: {
        color: RED,
    },
    qty: {
        [theme.breakpoints.down('sm')]: {
            ...CreateMargin(0, 15, 30, 15),
            alignItems: 'center',
        },
        ...CreateMargin(0, 15, 30, 0),
        ...FlexColumn,
        alignItems: 'center',
    },
}));
