import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY, WHITE } from '@theme_color';
import {
    CreateBorder, CreateMargin, CreatePadding, FlexColumn,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        background: 'url(/assets/img/warpin_image_background.png)',
        backgroundRepeat: 'repeat-x',
        backgroundSize: '50%',
    },
    container: {
        width: '100%',
        height: '100%',
        ...FlexColumn,
        ...CreatePadding(20, 20, 20, 20),
    },
    cardCatalogInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 auto',
        alignItems: 'center',
        width: '100%',
    },
    cmsContainer: {
        borderRadius: '5px',
        overflow: 'hidden',
        margin: '25px auto',
        maxWidth: 450,
        width: '100%',
        [theme.breakpoints.down(480)]: {
            maxWidth: 350,
        },
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        padding: 10,
        width: 100,
        height: 100,
        display: 'flex',
        ...CreateBorder(0, '1px', 0, 0, GRAY_PRIMARY),
    },
    headContainerNoBanner: {
        backgroundColor: WHITE,
        height: '40vh',
    },
    header: {
        left: '50%',
        right: '50%',
        top: '11px',
        position: 'absolute',
        borderBottom: 'none',
        fontWeight: 'bold',
    },
    breadcrumbs: {
        padding: '16px 16px 0',
        position: 'relative',
        zIndex: 10,
    },
    categoryName: {
        padding: 0,
        fontWeight: 600,
        ...CreateMargin(0, 0, 0, 15),
    },
    verificationContainer: {
        width: '100%',
        justifyContent: 'center',
    },
    bannerWrapper: {
        maxWidth: '320px',
        margin: '0 auto -1rem',
        padding: '0 2rem',
    },
    btnBeforeLogin: {
        textTransform: 'capitalize',
        boxShadow: 'none',
        background: PRIMARY,
        marginTop: '1.2rem',
    },
}));

export default useStyles;
