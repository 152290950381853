import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    BLACK, GRAY_PRIMARY, GRAY_SECONDARY, PRIMARY, WHITE,
} from '@theme_color';
import {
    CenterAbsolute, Centering, CreateBorder, CreateMargin, CreatePadding, FlexColumn, FlexRow,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '-50px 0px 0px 0px !important',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
        },
        position: 'relative',
        '& .customizable-container': {
            marginTop: 15,
        },
        '& .product-video': {
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            '& iframe': {
                height: 'auto',
                padding: 'auto 0',
            },
            [theme.breakpoints.up('sm')]: {
                '& iframe': {
                    height: '572px',
                },
            },
        },
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        width: '100%',
        // [theme.breakpoints.down('lg')]: {
        //     marginTop: 50,
        // },
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            marginTop: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0',
            marginTop: 0,
        },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(15, 20, 40, 20),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),

        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
        },
    },

    footer: {
        maxWidth: '500px',
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        ...CenterAbsolute,
        background: 'rgba(255,255,255,0.7)',
        // opacity : 0.7,
        ...CreatePadding(20, 20, 10, 20),
        zIndex: theme.zIndex.drawer + 1,
        '& div': {
            marginBottom: 20,
            '& div': {
                marginBottom: 0,
            },
        },
    },

    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },

    btnAddToCard: {
        width: '100%',
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
            width: 316,
        },
        [theme.breakpoints.down('sm')]: {
            width: 316,
        },
        [theme.breakpoints.down('xs')]: {
            width: 316,
            marginBottom: '-5px',
        },
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: BLACK,
        // borderRadius: '100px !important',
        // '& div': {
        //     '& div': {
        //         marginBottom: '0px !important',
        //     },
        // },
        '& span': {
            color: `${BLACK} !important`,
            '& span': {
                color: `${BLACK} !important`,
            },
        },
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${BLACK} !important`,
    },

    titleContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    titlePriceContainer: {
        ...FlexColumn,
        flex: 1,
        fontSize: 20,
        '& .price_text': {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
        },
    },

    shareContainer: {
        ...FlexRow,
        justifyContent: 'flex-end',
        flex: 1,
        '& > div': {
            textAlign: 'right !important',
        },
        [theme.breakpoints.down('sm')]: {
            '& button span': {
                fontSize: 8,
            },
        },
    },

    shareRootContainer: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
        [theme.breakpoints.up('sm')]: {
            ...CreatePadding(8, 16, 16, 16),
        },
    },

    btnShare: {
        margin: '0px !important',
    },

    iconShare: {
        color: PRIMARY,
        fontSize: 25,
        ...CreateMargin(0, 0, 0, 0),
    },

    carouselContainer: {
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    desc: {
        ...CreateMargin(12, 0, 12, 0),
        textAlign: 'center',
    },
    ratingContainer: {
        ...FlexRow,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 16,
        },
    },
    priceTiersContainer: {
        marginTop: '2rem',
        ...FlexColumn,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 16,
        },
        '& > *': {
            flex: '0 0 100%',
            margin: '5px 0',
            fontSize: 12,
        },
    },
    btnLoadReview: {
        ...Centering,
        textAlign: 'center',
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        marginLeft: '0 !important',
    },
    tabs: {
        paddingTop: '40px',

        [theme.breakpoints.down('sm')]: {
            paddingTop: '140px',
        },
    },
    shareTitle: {
        marginTop: 20,
        fontSize: 12,
    },
    bannerProduct: {
        width: '99%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            height: '572px',
        },
    },
    bannerLiteTop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    bannerLiteTopMobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    bannerLiteLabel: {
        ...CreatePadding(10, 0, 10, 0),
    },
    bannerLiteAfter: {
        ...CreatePadding(10, 0, 10, 0),
    },
    shareIcon: {
        width: '100%',
    },
    rowItem: {
        ...FlexRow,
        justifyContent: 'space-around',
        width: '350px',
    },
    btnCompare: {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        width: '120px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sellerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        width: '100%',
    },
    imageContainer: {
        float: 'left',
        marginRight: 20,
    },
    img: {
        width: '60px',
        height: 'auto',
        borderRadius: '50%',
    },
    sellerLogo: {
        [theme.breakpoints.up('xs')]: {
            width: '64px !important',
            height: '64px !important',
            '& img': {
                height: '75% !important',
            },
        },
    },
    btnAddToCardContainer: {
        display: 'none',
    },
    viewsellerCompany: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sellerCompany: {
        display: 'flex',
        marginTop: 4,
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
        },
    },
    iconSeller: {
        width: 16,
        height: 16,
        marginRight: 5,
    },
    productCompany: {
        ...CreateMargin(0, 0, 5, 0),
        fontSize: 12,
        fontWeight: '600',
    },
    verified: {
        color: '#0D7891',
    },
    officialDistributor: {
        color: '#E68902',
    },
    grospin: {
        color: '#7DA11B',
    },
    btnVisit: {
        textDecoration: 'none',
        boxShadow: 'none !important',
        width: '100%',
        textTransform: 'none',
        cursor: 'pointer',
        background: '#fff',
        padding: '4px 24px',
        borderRadius: 5,
        border: '2px solid #858080',
        marginLeft: 6,
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            padding: '2px 4px',
        },
        '&:hover, :active': {
            background: '#fff',
        },
    },
    tierPriceCustom: {
        background: PRIMARY,
        padding: '.5rem .75rem',
        borderRadius: '4px',
        fontWeight: '600',
    },
}));

export default useStyles;
