import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, GRAY_THIRD, PRIMARY, WHITE,
} from '@theme_color';
import {
    CenterAbsolute, Centering, ClearMarginPadding, CreateMargin, CreatePadding, FlexColumn, FlexRow,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    container: {},
    skeleton: {
        columnGap: 10,
        display: 'flex',
    },
    skeletonDesc: {
        width: '100%',
    },
    skeletonBottom: {
        display: 'flex',
        columnGap: 100,
    },
    skeletonContainer: {
        height: '100%',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        ...CreatePadding(10, 10, 0, 10),
        ...CreateMargin(20, 20, 0, 20),
    },
    itemContainer: {
        width: '100%',
        display: 'flex',
        minHeight: 215,
        // [theme.breakpoints.up('xmed')]: {
        //     height: '80%',
        // },
        overflow: 'hidden',
        ...CreatePadding(10, 10, 0, 10),
        ...CreateMargin(0, 0, 0, 0),
        position: 'relative',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        '& .MuiSkeleton-rect': {
            paddingBottom: '120%',
        },
        '& a': {
            cursor: 'pointer',
        },
    },
    quickView: {
        '& .btn-quick-view-list': {
            clear: 'both',
            background: 'transparent',
            border: 'none',
            fontWeight: 'bold',
            color: GRAY_THIRD,
            cursor: 'pointer',
            outline: 'none',
            display: 'block',
            marginTop: 15,
        },
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                '& .btn-quick-view': {
                    display: 'inline-block',
                },
                boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.15)',
            },
        },
    },
    listContainer: {
        width: 'calc(100% - 20px)',
        // display: 'flex',
        height: '100%',
        overflow: 'hidden',
        ...CreatePadding(10, 10, 0, 10),
        ...CreateMargin(0, 10, 15, 10),
        position: 'relative',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        '& .MuiSkeleton-rect': {
            paddingBottom: '120%',
        },
        '& a': {
            cursor: 'pointer',
        },
    },
    badgesNewSales: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 5,
        borderRadius: 5,
        zIndex: 1,
        ...FlexRow,
        justifyContent: 'space-between',
        width: '100%',
        padding: 15,
    },
    badgesNewSalesList: {
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        borderRadius: 5,
        zIndex: 1,
        ...FlexRow,
        justifyContent: 'space-between',
        padding: 15,
    },
    imgItem: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        ...Centering,
        borderRadius: 5,
        marginBottom: 10,
        position: 'relative',
        objectFit: 'cover',
        aspectRatio: '64/78',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        '& .btn-quick-view': {
            position: 'absolute',
            zIndex: 2,
            clear: 'both',
            background: 'rgba(0,0,0, 0.3)',
            padding: 5,
            border: 'none',
            fontWeight: 'bold',
            color: WHITE,
            cursor: 'pointer',
            outline: 'none',
            display: 'none',
            ...CenterAbsolute,
        },
        '& div': {
            borderRadius: 5,
        },
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                '& .btn-quick-view': {
                    display: 'inline-block',
                },
            },
        },
    },
    imgProduct: {
        width: '100%',
        height: '100%',
    },
    detailItem: {
        marginLeft: 20,
        height: 'auto',
        paddingTop: 14,
        position: 'relative',
    },
    listImgItem: {
        position: 'relative',
        maxWidth: '100%',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 0px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        '& .btn-quick-view': {
            position: 'absolute',
            zIndex: 2,
            clear: 'both',
            background: 'rgba(0,0,0, 0.3)',
            borderRadius: 5,
            padding: 5,
            border: 'none',
            fontWeight: 'bold',
            color: WHITE,
            cursor: 'pointer',
            outline: 'none',
            display: 'none',
            ...CenterAbsolute,
        },
        [theme.breakpoints.up('sm')]: {
            ...Centering,
            '&:hover': {
                '& .btn-quick-view': {
                    display: 'inline-block',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'none !important',
        },
    },
    listDetailItem: {
        height: 'auto',
        position: 'relative',
        flex: 0.8,
    },
    descItem: {
        ...FlexColumn,
        maxWidth: '100%',
        position: 'relative',
    },
    productTitle: {
        ...CreateMargin(0, 0, 5, 0),
        fontSize: 12,
        fontWeight: '400',
    },
    productCompany: {
        ...CreateMargin(0, 0, 5, 0),
        fontSize: 12,
        fontWeight: '600',
    },
    verified: {
        color: '#0D7891',
    },
    officialDistributor: {
        color: '#E68902',
    },
    grospin: {
        color: '#7DA11B',
    },

    btnFeed: {
        ...ClearMarginPadding,
        width: 20,
        height: 20,
        position: 'absolute',
        top: '0px',
        right: 0,
        textAlign: 'right',
    },
    btnCompare: {
        ...ClearMarginPadding,
        width: 20,
        height: 20,
        position: 'absolute',
        top: '0px',
        right: 5,
        textAlign: 'right',
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: '-10px',
            right: 20,
        },
        [theme.breakpoints.up('xm')]: {
            position: 'absolute',
            top: '-10px',
            right: 20,
        },
        [theme.breakpoints.up('xs')]: {
            position: 'absolute',
            top: '-10px',
            right: 20,
        },
    },
    productLinkButton: {
        maxWidth: 'calc(100% - 34px)',
    },
    iconFeed: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
    },
    iconCompare: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
    },
    iconActive: {
        color: PRIMARY,
    },
    colorContainer: {
        ...FlexRow,
        ...CreatePadding(10, 10, 0, 0),
    },
    btnColor: {
        ...CreateMargin(0, 5, 0, 0),
    },
    feedContainer: {
        position: 'absolute',
        width: '20px',
        top: '-4px',
        right: '45px',
        textAlign: 'right',
    },
    btnAddToCart: {
        width: '100%',
        alignItems: 'center',
        paddingTop: 20,
        bottom: 0,
    },
    itemConfigurable: {
        width: '20px !important',
        height: '20px !important',
        ...CreateMargin(5, 5, 5, 5),
    },
    customBtnAddToCard: {
        width: '100%',
        boxShadow: 'none !important',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            // right: '-125%',
            // '-webkit-transform': 'translateX(-50%)',
            // transform: ' translateX(-50%)',
            height: '2.25rem',
            borderRadius: '.25rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '3rem',
            borderRadius: '.25rem !important',
        },
        [theme.breakpoints.up('xmed')]: {
            width: '3rem',
            borderRadius: '.25rem !important',
        },
        height: '2.25rem',
        // bottom: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: '.25rem !important',
        fontSize: 12,
    },
    infoSeller: {
        ...FlexColumn,
        marginTop: '0.5rem',
        // [theme.breakpoints.up('xmed')]: {
        //     marginTop: '2.5rem',
        // },
    },
    sellerCompany: {
        display: 'flex',
    },
    iconSeller: {
        width: 16,
        height: 16,
        marginRight: 5,
    },
    sellerBtn: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
}));
