import React from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { getResolver, setResolver } from '@helper_localstorage';

const Footer = dynamic(() => import('@plugin_optionitem/components/Footer'), { ssr: true });

const SimpleOptionView = (props) => {
    const {
        qty = 1,
        setQty = () => { },
        handleAddToCart = () => { },
        t,
        loading = false,
        disabled = false,
        showQty = true,
        showAddToCart = true,
        urlKey,
        isPLP = false,
        ...other
    } = props;

    const redirectPDP = async () => {
        const urlResolver = getResolver();
        urlResolver[`/${urlKey}`] = {
            type: 'PRODUCT',
        };
        await setResolver(urlResolver);
        Router.push(`/${urlKey}`);
    };

    return (
        <>
            <Footer
                loading={loading}
                disabled={isPLP ? false : disabled}
                showQty={showQty}
                handleAddToCart={isPLP ? redirectPDP : handleAddToCart}
                qty={qty}
                setQty={setQty}
                t={t}
                showAddToCart={showAddToCart}
                {...other}
            />
        </>
    );
};

export default SimpleOptionView;
