export const getIconByStore = (store) => {
    if (store === 'WPD') {
        return '/assets/img/seller-verified-badge.svg';
    }
    if (store === 'Grosir') {
        return '/assets/img/seller-grospin-badge.svg';
    }
    return '/assets/img/seller-officialdistributor-badge.svg';
};

export default getIconByStore;
