import ButtonQty from '@common_buttonqty';
import Typography from '@common_typography';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import React from 'react';

import { useRouter } from 'next/router';

import useStyles from '@plugin_optionitem/components/Footer/style';

const Button = dynamic(() => import('@common_button'), { ssr: true });

const ConfigurableView = (props) => {
    const {
        loading,
        disabled,
        showQty = true,
        handleAddToCart,
        qty,
        setQty,
        t,
        showAddToCart = true,
        customStyleBtnAddToCard = '',
        maxQty = 10000,
        customButton,
        customQty = false,
        freeItemsData,
        route,
    } = props;
    const styles = useStyles();
    const router = useRouter();

    if (customButton) {
        return customButton;
    }

    return (
        <>
            {showQty && (
                <div className={classNames(styles.qty, 'product-OptionItem-qty')}>
                    <Typography type="bold" variant="span">
                        {t('common:title:qty')}
                    </Typography>
                    <ButtonQty value={qty} onChange={setQty} max={customQty ? freeItemsData.quantity : maxQty} disabled={disabled} route={route} />
                </div>
            )}
            {showAddToCart && (
                <div className={styles.btnAddToCardContainer}>
                    <Button
                        id="plugin-addToCart-btn"
                        className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                        color="primary"
                        onClick={() => {
                            const userDataCookies = Cookies.get('cdt');
                            const cartIdCoookies = Cookies.get('nci');
                            const userData = userDataCookies ? JSON.parse(userDataCookies) : {};
                            const cartId = cartIdCoookies || {};
                            if (!router.pathname.includes('catalog')) {
                                // eslint-disable-next-line no-undef
                                smartech('dispatch', 'op_productmodal_addtocart_click', {
                                    cart_id: `${cartId}`,
                                    qty: `${qty}`,
                                    email: `${userData.email}`,
                                    wp_code: `${userData.wp_code}`,
                                    uom_id: props.selected && props.selected.uom ? `${props.selected.uom}` : '',
                                    url_key: `${_.get(props, 'route.asPath', 'default-url-key').replace('/', '')}`,
                                    // eslint-disable-next-line radix
                                    total_price: `${_.get(props, 'price.priceRange.maximum_price.final_price.value', 0) * qty}`,
                                });
                            }
                            handleAddToCart();
                        }}
                        loading={loading}
                        disabled={disabled}
                    >
                        <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                            Beli
                        </Typography>
                    </Button>
                </div>
            )}
        </>
    );
};

export default ConfigurableView;
