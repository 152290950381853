/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PriceFormat from '@common_priceformat';
import Typography from '@common_typography';
import { modules } from '@config';
import Button from '@material-ui/core/IconButton';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import useStyles from '@plugin_productitem/style';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

const Detail = (props) => {
    const {
        spesificProduct,
        handleClick,
        name,
        handleFeed,
        __typename,
        price_range,
        price_tiers,
        feed,
        id,
        special_from_date,
        special_to_date,
        enableWishlist,
        handleSetCompareList,
        enablePrice = true,
        enableProductCompare,
        urlKey,
        seller,
    } = props;
    const styles = useStyles();
    const router = useRouter();
    const classFeedActive = classNames(styles.iconFeed, styles.iconActive);
    const FeedIcon = feed ? <Favorite className={classFeedActive} /> : <FavoriteBorderOutlined className={styles.iconFeed} />;
    const showWishlist = typeof enableWishlist !== 'undefined' ? enableWishlist : modules.wishlist.enabled;
    return (
        <div className={styles.descItem}>
            {showWishlist && (
                <Button className={classNames(styles.btnFeed, 'hidden-desktop')} onClick={() => handleFeed(props)}>
                    {FeedIcon}
                </Button>
            )}
            {enableProductCompare && (
                <Button className={styles.btnCompare} onClick={() => handleSetCompareList(id)}>
                    <CompareArrowsIcon className={classNames(styles.iconCompare, 'hidden-desktop')} />
                </Button>
            )}
            <Link href="/[...slug]" as={`/${urlKey}`} className={styles.productLinkButton}>
                <a
                    onClick={() => {
                        handleClick(props);
                        const userDataCookies = Cookies.get('cdt');
                        const userData = userDataCookies ? JSON.parse(userDataCookies) : null;
                        if (router.route.includes('/seller/')) {
                            // eslint-disable-next-line no-undef
                            smartech('dispatch', 'op_catalog_product_click', {
                                source: 'seller_page',
                                click_on: 'product_image',
                                seller_id: `${seller.seller_id}`,
                                sku: `${props.sku}`,
                                email: `${userData.email}`,
                                wp_code: `${userData.wp_code}`,
                            });
                        } else if (router.route.includes('/catalogsearch/')) {
                            // eslint-disable-next-line no-undef
                            smartech('dispatch', 'op_catalog_product_click', {
                                source: 'search_page',
                                click_on: 'product_image',
                                seller_id: `${seller.seller_id}`,
                                sku: `${props.sku}`,
                                email: `${userData.email}`,
                                wp_code: `${userData.wp_code}`,
                            });
                        } else {
                            // eslint-disable-next-line no-undef
                            smartech('dispatch', 'op_catalog_product_click', {
                                source: 'catalog_page',
                                click_on: 'product_image',
                                seller_id: `${seller.seller_id}`,
                                sku: `${props.sku}`,
                                email: `${userData.email}`,
                                wp_code: `${userData.wp_code}`,
                            });
                        }
                    }}
                    className={styles.productTitle}
                    id="plugin-productTitle-typography"
                >
                    <Typography variant="p" className={styles.productTitle} letter="capitalize">
                        {name}
                    </Typography>
                </a>
            </Link>
            {enablePrice && (
                <PriceFormat
                    // eslint-disable-next-line camelcase
                    priceRange={spesificProduct.price_range ? spesificProduct.price_range : price_range}
                    // eslint-disable-next-line camelcase
                    priceTiers={spesificProduct.price_tiers ? spesificProduct.price_tiers : price_tiers}
                    productType={__typename}
                    specialFromDate={special_from_date}
                    specialToDate={special_to_date}
                />
            )}
        </div>
    );
};

export default Detail;
