/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-restricted-imports */
/* eslint-disable react/no-danger */
// import TabView from '@common_tabs';
import Typography from '@common_typography';
import useStyles from '@core_modules/catalog/pages/category/components/style';
import { getStoreHost } from '@helpers/config';
import { Box, Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Product from '@plugin_productlist';
import ProductListSkeleton from '@plugin_productlist/components/ProductListSkeleton';
import { getAppEnv } from '@root/core/helpers/env';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Router, { useRouter } from 'next/router';
import React from 'react';

const BannerView = dynamic(() => import('@common_image/LazyImage'), { ssr: false });

// sementara di comment dlu, untuk custom filter memakai aggregations product
// import { getFilter } from '../../../services/graphql';

const categoryTabs = (category) => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < category.length; index++) {
        data.push(category[index].name);
    }
    return data;
};

const CategoryPage = ({ data, loading, storeConfig, t, isLogin, dataCustomer, loadingCustomer, ...other }) => {
    const styles = useStyles();
    const router = useRouter();
    // const [value] = React.useState(0);
    const [categoryList, setCategoryList] = React.useState([]);

    React.useEffect(() => {
        if (!loading && data && data.categoryList && data.categoryList[0]) {
            setCategoryList(data.categoryList[0]);
        }
    }, [data]);

    // const categoryList = data && data.categoryList && data.categoryList[0];
    let dataBanner = [];
    // eslint-disable-next-line consistent-return
    const handleChange = (event, newValue) => {
        if (_.isEmpty(categoryList?.child)) {
            return false;
        }
        Router.push('/[...slug]', `/${categoryList && categoryList?.children[newValue - 1]?.url_path}`);
    };

    // const { verification_status } = dataCustomer;

    // React.useEffect(() => {
    //     if (verification_status === 'unverified' || verification_status === 'unvalidated') {
    //         Router.push('/landing-page-apply-form');
    //     }
    // }, [verification_status]);

    const { tnc_agreement } = dataCustomer;

    React.useEffect(() => {
        if (tnc_agreement !== 1) {
            Router.push('/syarat-dan-ketentuan');
        }
    }, [tnc_agreement]);

    const handleClickVerification = () => {
        router.push('/customer/account/verification/data');
    };

    // eslint-disable-next-line no-unused-vars
    const verificationScreenContents = {
        unverified: {
            title: 'Mulai Belanja, yuk!',
            subTitle: `Temukan berbagai jenis barang kebutuhan sehari-hari untuk memenuhi stock Warung Juragan.
                          Lengkapi data dulu yuk dengan klik tombol dibawah ini!`,
            btnTitle: 'Verifikasi Data',
            hideBtn: false,
        },
        on_progress: {
            title: 'Datamu Sedang Diverifikasi',
            subTitle: 'Status verifikasi Juragan sedang dalam proses. Juragan bisa kembali memesan setelah pemberitahuan lebih lanjut.',
            BtnTitle: '',
            hideBtn: true,
        },
        unvalidated: {
            title: 'Mulai Belanja, yuk!',
            subTitle:
                'Halo Juragan!\n' +
                'Temukan berbagai jenis barang kebutuhan sehari-hari untuk memenuhi stock Warung Juragan. ' +
                'Lengkapi data dulu yuk dengan klik tombol dibawah ini!',
            btnTitle: 'Lengkapi Data',
            hideBtn: false,
        },
    };

    const [shouldShowVerificationScreen, setShouldShowVerificationScreen] = React.useState(false);

    React.useEffect(() => {
        if (isLogin) {
            if (dataCustomer && dataCustomer.customer && dataCustomer.customer.verification_status) {
                setShouldShowVerificationScreen(
                    Object.prototype.hasOwnProperty.call(verificationScreenContents, dataCustomer.customer.verification_status)
                );
            }
        } else {
            Router.push('/customer/account/login');
        }
    }, [dataCustomer, isLogin]);

    if (categoryList && categoryList.image_path) {
        dataBanner = [
            {
                imageUrl: categoryList.image_path,
                link: categoryList.url_path,
                description: categoryList.description,
            },
        ];
    }
    const urlDest = new URL(getStoreHost(getAppEnv()));
    let UrlString = '';
    if (dataBanner.length > 0) {
        if (dataBanner[0].imageUrl.toLowerCase().indexOf(urlDest.hostname) === -1) {
            UrlString = `${urlDest.protocol}//${urlDest.hostname}${dataBanner[0].imageUrl}`;
        } else {
            UrlString = dataBanner[0].imageUrl;
        }
    } else {
        UrlString = '';
    }
    // sementara di comment dlu, untuk custom filter memakai aggregations product
    // const customFilter = getFilter(categoryList.id);
    let breadcrumbsData = [];
    if (categoryList && categoryList.breadcrumbs && categoryList.breadcrumbs.length > 0) {
        breadcrumbsData = categoryList.breadcrumbs.map((bc) => ({
            label: bc.category_name,
            link: `/${bc.category_url_path}`,
            active: false,
            id: bc.category_id,
        }));
    }
    breadcrumbsData.push({
        label: categoryList && categoryList.name,
        link: '#',
        active: true,
    });

    const [splitUrl, setSplitUrl] = React.useState(null);

    const image_product_height = storeConfig?.pwa?.image_product_height;
    const image_product_width = storeConfig?.pwa?.image_product_width;

    React.useEffect(() => {
        if (categoryList && categoryList.url_path) {
            setSplitUrl(categoryList.url_path.split('/'));
        }
    }, [categoryList]);
    // const urlPath = categoryList && categoryList.url_path;
    // const splitUrl = urlPath.split('/');

    if (loadingCustomer) {
        return <ProductListSkeleton />;
    }

    if (dataCustomer && dataCustomer.customer && dataCustomer.customer.verification_status && shouldShowVerificationScreen) {
        const { title, subTitle, btnTitle, hideBtn } = verificationScreenContents[dataCustomer.customer.verification_status];
        return (
            title && (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        height: 'calc(100vh - 200px)',
                    }}
                >
                    <Grid container className={styles.verificationContainer}>
                        <Grid item xs={12}>
                            <Box className={styles.bannerWrapper}>
                                <Image
                                    src="/assets/img/warpin_masuk_dulu_banner.webp"
                                    alt="login-banner"
                                    layout="responsive"
                                    width="300"
                                    height="auto"
                                    objectFit="contain"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h1" size="16" type="bold" align="center" letter="capitalize">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                        >
                            <Typography variant="p" type="semiBold" size="12" align="center">
                                {subTitle}
                            </Typography>
                        </Grid>
                        {!hideBtn && (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Button
                                    className={styles.btnBeforeLogin}
                                    customRootStyle={{
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={isLogin ? handleClickVerification : () => Router.push('/customer/account/login')}
                                >
                                    {btnTitle}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )
        );
    }

    return (
        <>
            <style jsx>
                {`
                    .cms-block-category :global(img) {
                        width: 100%;
                        max-width: 100%;
                    }
                `}
            </style>
            <div className={splitUrl && splitUrl[0] === 'brand' ? styles.outerContainer : null}>
                <div className={styles.container}>
                    {categoryList && categoryList.url_path && categoryList.url_path.substring(0, 5) === 'brand' ? (
                        <Card
                            style={{
                                display: 'flex',
                            }}
                            elevation={3}
                            className={styles.cardCatalogInfo}
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={3}>
                                    <div className={styles.headContainer}>
                                        {dataBanner.length > 0 ? (
                                            <BannerView
                                                src={UrlString}
                                                width={
                                                    typeof image_product_width === 'string' ? parseInt(image_product_width, 0) : image_product_width
                                                }
                                                height={
                                                    typeof image_product_height === 'string'
                                                        ? parseInt(image_product_height, 0)
                                                        : image_product_height
                                                }
                                                showArrow={dataBanner.length > 1}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box
                                        style={{
                                            paddingTop: '1rem',
                                        }}
                                    >
                                        <CardContent
                                            style={{
                                                flex: '1 0 auto',
                                                padding: 10,
                                            }}
                                        >
                                            <Typography variant="h3" className={styles.categoryName}>
                                                {categoryList.name}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : null}
                    {dataBanner[0] && dataBanner[0].description && (
                        /* eslint-disable-next-line react/no-danger */
                        <div
                            className={styles.cmsContainer}
                            dangerouslySetInnerHTML={{
                                __html: dataBanner[0].description,
                            }}
                        />
                    )}

                    {/* {categoryList && categoryList.url_path && categoryList.url_path.substring(0, 5) !== 'brand' ? (
                        <TabView
                            data={categoryList && categoryList.children && categoryTabs(categoryList.children)}
                            onChange={handleChange}
                            value={value}
                        />
                    ) : null} */}

                    {categoryList &&
                        (categoryList.display_mode === 'PRODUCTS_AND_PAGE' || categoryList.display_mode === 'PAGE') &&
                        categoryList.cms_block && (
                            <div
                                className="cms-block-category"
                                dangerouslySetInnerHTML={{
                                    __html: categoryList.cms_block.content,
                                }}
                            />
                        )}
                    {categoryList &&
                        (!categoryList.display_mode ||
                            categoryList.display_mode === 'PRODUCTS_AND_PAGE' ||
                            categoryList.display_mode === 'PRODUCTS') && (
                            <Product
                                // sementara di comment dlu, untuk custom filter memakai aggregations product
                                // customFilter={customFilter.loading ? [] : customFilter.data.getFilterAttributeOptions.data}
                                catId={categoryList.id}
                                categoryPath={categoryList.url_path}
                                catalog_search_engine={storeConfig.catalog_search_engine}
                                t={t}
                                category={categoryList && categoryList.children && categoryTabs(categoryList.children)}
                                dataTabs={categoryList && categoryList.children && categoryTabs(categoryList.children)}
                                onChangeTabs={handleChange}
                                storeConfig={storeConfig}
                                defaultSort={{
                                    key: 'price',
                                    value: 'ASC',
                                }}
                                {...other}
                            />
                        )}
                </div>
            </div>
        </>
    );
};

export default CategoryPage;
