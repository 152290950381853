import { makeStyles } from '@material-ui/core/styles';

import { GRAY_PRIMARY, WHITE, BLACK50 } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    listContainer: {
        height: 75,
        paddingTop: 10,
        width: '100%',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    firstListContainer: {
        height: 100,
    },
    topTitle: {
        display: 'block',
        width: '100%',
        height: '20px',
        paddingLeft: '0px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '10px',
    },
    imageContainer: {
        float: 'left',
        marginRight: 20,
    },
    img: {
        width: '50px',
        height: 'auto',
    },
    title: {
        paddingTop: 0,
        paddingBottom: '0rem',
        textTransform: 'uppercase',
        fontSize: 10,
    },
    titleSeller: {
        paddingTop: 2,
        paddingBottom: 5,
        textTransform: 'uppercase',
        fontSize: 10,
    },
    infoSeller: {
        display: 'flex',
    },
    iconSeller: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
        marginRight: 5,
    },
    price: {
        fontWeight: 'bold',
        fontSize: 10,
    },
    address: {
        fontSize: 10,
    },
    listContainerCategory: {
        width: '100%',
        padding: 10,
        paddingLeft: 0,
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    topTitleCategory: {
        paddingLeft: '0px',
    },
    breadcrumbs: {
        paddingBottom: 5,
        textTransform: 'uppercase',
        color: '#929292',
        fontSize: 10,
        fontStyle: 'italic',
    },
    titleCategory: {
        textTransform: 'uppercase',
        fontSize: 10,
    },
    textFieldSearchBoxAct: {
        backgroundColor: '#F5F5F5',
        borderRadius: 4,
        '& .MuiInputBase-adornedStart': {
            height: 40,
            paddingRight: '9px !important',
            [theme.breakpoints.down('xs')]: {
                paddingRight: '9px !important',
            },
            '& input': {
                fontSize: 14,
                color: 'black',
                fontWeight: 500,
            },
            '& fieldset': {
                border: 'none !important',
            },
        },
    },
    textFieldSearchBox: {
        backgroundColor: 'none',
        '& .MuiInputBase-adornedStart': {
            height: 40,
            backgroundColor: '#fff !important',
            paddingRight: '12px !important',
            '& input': {
                fontSize: 14,
                color: 'black',
                fontWeight: 500,
            },
            '& fieldset': {
                border: 'none !important',
            },
        },
        '& MuiInputBase-input': {
            fontSize: '12px !important',
            [theme.breakpoints.up('xs')]: {
                fontSize: '12px !important',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px !important',
            },
        },
    },
    btnIconDel: {
        right: 28,
        top: 25,
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 100,
    },
    buttonClear: {
        padding: '0 !important',
        backgroundColor: BLACK50,
        marginLeft: 10,
        '&:hover': {
            backgroundColor: BLACK50,
        },
    },
    iconClear: {
        padding: '3px',
        fontSize: '1.2rem !important',
        color: WHITE,
        strokeWidth: '2px',
        stroke: WHITE,
    },
}));

export default useStyles;
