import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from '@plugin_productitem/style';

const ProductItemSkeleton = () => {
    const styles = useStyles();
    return (
        <div className={styles.skeletonContainer}>
            <div className={styles.skeleton}>
                <Skeleton variant="rect" width="50%" height={100} />
                <div className={styles.skeletonDesc}>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="30%" />
                </div>
            </div>
            <div className={styles.skeletonBottom}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rect" width="25%" height={40} />
            </div>
        </div>
    );
};

export default ProductItemSkeleton;
