/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
import useStyles from '@core_modules/theme/components/header/desktop/components/autocomplete/style';
import { getProduct } from '@core_modules/theme/services/graphql';
// import { useTranslation } from '@i18n';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Cookies from 'js-cookie';
import Router, { useRouter } from 'next/router';

let globalTimeout = null;

const generateItemData = (product) => {
    const result = [];
    for (let index = 0; index < product.items.length; index++) {
        const element = product.items[index];
        const prod = {
            id: element.id,
            name: element.name,
            seller_name: element?.seller?.seller_name,
            url_key: element.url_key,
            position: index,
            small_image: element.small_image,
            price_tiers: element.price_tiers,
            price_range: element.price_range,
            type: 'product',
        };
        result.push(prod);
    }
    return result;
};

export default function ComboBox(props) {
    const {
        handleSearch,
        setValue,
        OptionsItem,
        forcePopupIcon = false,
        width = 300,
        maxHeight = '80vh',
        setSearchActive,
        searchActive,
        setKeyword,
        // placeholder,
        // isHeader,
    } = props;
    // const { t } = useTranslation(['common']);
    const [item, setItem] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [close, setClose] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [cloneval, setcloneVal] = React.useState('');
    // eslint-disable-next-line no-useless-escape
    const [actGetProduct, { loading, data, called }] = getProduct(search && search !== '' && search.replace(/\\/g, '').replace(/\"/g, ''));

    const styles = useStyles();
    let itemData = [];

    if (data && !open && !loading) {
        itemData = generateItemData(data.products);
    }

    const router = useRouter();
    const { q: searchQuery } = router.query;
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (searchQuery) {
            setInputValue(decodeURIComponent(searchQuery));
        } else {
            setInputValue('');
        }
    }, [searchQuery]);

    React.useEffect(() => {
        if (itemData.length > 0) {
            setItem(itemData);
            if (!close) {
                setOpen(true);
            }
        }
    }, [itemData.length]);

    const startAutocomplete = (e) => {
        // eslint-disable-next-line no-useless-escape
        setValue(e.target.value.replace(/\\/g, '').replace(/\"/g, ''));
        // eslint-disable-next-line no-useless-escape
        setKeyword(e.target.value.replace(/\\/g, '').replace(/\"/g, ''));
        // eslint-disable-next-line no-useless-escape
        const val = `${e.target.value.replace(/\\/g, '').replace(/\"/g, '')}`;
        setcloneVal(val);
        if (globalTimeout) {
            clearTimeout(globalTimeout);
        }

        globalTimeout = setTimeout(() => {
            setOpen(false);
            setClose(false);
            setSearch(val);
            if (!called) {
                actGetProduct();
            }
        }, 150);
    };

    const handleReset = () => {
        setInputValue('');
    };

    const handleKeyPress = (e) => {
        handleSearch(e);
    };
    return (
        <Autocomplete
            id="combo-box-demo"
            options={item}
            clearOnBlur={false}
            disableClearable={cloneval === ''}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.name === value.name}
            forcePopupIcon={forcePopupIcon}
            style={{ width, padding: '0px 5px 0px 5px' }}
            openOnFocus={false}
            open={false}
            ListboxProps={{ style: { maxHeight, height: 'auto' } }}
            renderOption={(option) => <OptionsItem {...option} />}
            renderInput={(params) => (
                <TextField
                    {...params}
                    id="standard-basic"
                    autoFocus={!!searchActive}
                    placeholder={searchActive ? 'Silahkan cari produk di sini...' : 'Belanja apa hari ini?'}
                    onFocus={() => {
                        setSearchActive(true);
                        if (Cookies.get('cdt')) {
                            const userData = JSON.parse(Cookies.get('cdt'));
                            smartech('dispatch', 'op_catalog_searchbar_click', {
                                source: searchActive ? 'searchpage' : 'homepage',
                                email: `${userData.email}}`,
                                wp_code: `${userData.wp_code}`,
                            });
                        } else {
                            smartech('dispatch', 'op_catalog_searchbar_click', {
                                source: searchActive ? 'searchpage' : 'homepage',
                            });
                        }
                    }}
                    onBlur={() => {
                        setClose(true);
                        setOpen(false);
                        // if (!isHeader) setSearchActive(false);
                    }}
                    variant="outlined"
                    className={searchActive ? styles.textFieldSearchBoxAct : styles.textFieldSearchBox}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            <IconButton
                                className={styles.buttonClear}
                                onClick={handleReset}
                                style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                            >
                                <ClearIcon className={styles.iconClear} />
                            </IconButton>
                        ),
                    }}
                />
            )}
            inputValue={inputValue}
            onInputChange={(e, value, reason) => {
                if (e && e.type === 'blur') {
                    setInputValue('');
                } else if (reason !== 'reset') {
                    setInputValue(value);
                    startAutocomplete(e);
                }
            }}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e, value) => {
                if (value) {
                    const sharedProp = {
                        name: value?.name || '',
                        small_image: value?.small_image || {},
                        price: value?.price_range ? { priceRange: value.price_range, priceTiers: value.price_tiers || [] } : {},
                    };
                    setOpen(false);
                    setClose(true);
                    if (value.type === 'seller') {
                        Router.push(
                            {
                                pathname: '/[...slug]',
                                query: {
                                    productProps: JSON.stringify(sharedProp),
                                },
                            },
                            `/seller/${value.id}`,
                        );
                    } else {
                        Router.push(
                            {
                                pathname: '/[...slug]',
                                query: {
                                    productProps: JSON.stringify(sharedProp),
                                },
                            },
                            `/${value.url_key}`,
                        );
                    }
                }
            }}
            onClose={() => {
                setClose(true);
                setOpen(false);
            }}
        />
    );
}
