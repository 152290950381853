/* eslint-disable arrow-body-style */

const FeaturedBrands = () => {
    return (
        <>
            {/* {useTitle && <h4 align="center">{t('brands:featuredBrands')}</h4>} */}
            {/* <Carousel showArrow={desktop} data={featured} Item={ItemFeatured} /> */}
        </>
    );
};

export default FeaturedBrands;
