import { getLocalStorage } from '@root/core/helpers/localstorage';

export function buildSellerIdFilter() {
    const sellerIds = getLocalStorage('available_seller') || [];
    return {
        type: 'seller_id',
        value: sellerIds.join(','),
    };
}

export function buildSellerIdFilterGetSeller() {
    const sellerIds = getLocalStorage('available_seller') || [];
    return sellerIds;
}

export default {
    buildSellerIdFilter,
};
