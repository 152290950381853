/* eslint-disable prefer-destructuring */
import generateSchemaOrg from '@core_modules/catalog/helpers/schema.org';
import Content from '@core_modules/catalog/pages/category/components';
import { getCategory, getPwaConfig } from '@core_modules/catalog/services/graphql';
import { getCustomer } from '@core_modules/customer/services/graphql';
import { getLoginInfo } from '@helpers/auth';
import { StripHtmlTags } from '@helper_text';
import Layout from '@layout';
import dynamic from 'next/dynamic';

const ErrorView = dynamic(() => import('@core_modules/error/pages/default'), { ssr: false });
const SkeletonView = dynamic(() => import('@core_modules/catalog/pages/category/components/Skeleton'), { ssr: false });

const Page = (props) => {
    const {
        categoryId, storeConfig: configStore, pageConfig = {}, ...other
    } = props;
    const { loading, data } = getCategory({
        productSize: configStore?.pwa?.page_size || 10,
        id: categoryId,
    });
    const { data: dataConfig } = getPwaConfig();
    const { data: dataCustomer, loading: loadingCustomer } = getCustomer(configStore);
    const storeConfig = dataConfig?.storeConfig || {};
    let config = {
        ...pageConfig,
    };
    let schemaOrg = null;
    let ogDesc;
    let ogKeyword;
    let category = null;
    const isLogin = getLoginInfo();
    if (data && data.categoryList[0]) {
        // eslint-disable-next-line prefer-destructuring
        category = data.categoryList[0];
        schemaOrg = generateSchemaOrg(category, storeConfig);
        if (data.categoryList[0].meta_description || data.categoryList[0].description) {
            ogDesc = StripHtmlTags(data.categoryList[0].meta_description || data.categoryList[0].description) || '';
        }
        if (data.categoryList[0].meta_keywords) {
            ogKeyword = StripHtmlTags(data.categoryList[0].meta_keywords) || '';
        }
        const generateBottomNav = () => {
            const urlPath = data.categoryList[0].url_path;
            const splitUrl = urlPath.split('/');
            if (splitUrl[0] === 'shop') {
                return 'pesan';
            }
            if (splitUrl[0] === 'brand') {
                return 'brand';
            }
            return 'browse';
        };
        config = {
            title: data.categoryList[0]?.meta_title || data.categoryList[0]?.name || '',
            headerTitle: data || !data.categoryList[0].image_path ? data.categoryList[0].name : '',
            header: data && data.categoryList[0].image_path ? 'absolute' : 'relative', // available values: "absolute", "relative", false (default)
            bottomNav: generateBottomNav(),
            pageType: 'category',
            ogContent: {
                keywords: ogKeyword,
                'og:description': ogDesc,
            },
            schemaOrg,
        };
    }
    if (loading && !data && loadingCustomer) {
        const pwaConfig = storeConfig?.pwa || {};
        return (
            <Layout {...props} pageConfig={config}>
                <SkeletonView {...pwaConfig} />
            </Layout>
        );
    }

    if (!loading && data && !data.categoryList[0] && !loadingCustomer) {
        return <ErrorView statusCode={404} {...props} />;
    }
    return (
        <Layout {...props} pageConfig={config} data={category} isPlp>
            <Content
                categoryId={categoryId}
                data={data}
                loading={loading}
                {...other}
                storeConfig={storeConfig}
                dataCustomer={dataCustomer}
                loadingCustomer={loadingCustomer}
                isLogin={isLogin}
            />
        </Layout>
    );
};

export default Page;
