import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SECONDARY } from '@theme_color';
import { CreatePadding, FlexRow } from '@theme_mixins';
import { FONT_10 } from '@theme_typography';

const useStyles = makeStyles((theme) => ({
    filterContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        ...CreatePadding(15, 15, 15, 15),
        margin: '0 auto',
        maxWidth: 450,
        width: '100%',
        [theme.breakpoints.down(480)]: {
            maxWidth: 350,
        },
    },
    countProductText: {
        ...FONT_10,
        marginLeft: -90,
        paddingTop: 3,
        order: 1,
    },
    filterBtnContainer: {
        justifyContent: 'flex-end',
        ...FlexRow,
        alignItems: 'center',
        fontSize: 12,
    },
    btnFilter: {
        marginRight: -20,
        padding: 0,
    },
    iconFilter: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    iconGrid: {
        fontSize: 18,
        fontWeight: 'reguler',
        display: 'none',
    },
    iconList: {
        fontSize: 18,
        fontWeight: 'reguler',
        display: 'none',
    },
    leftWrapperFilter: {
        ...FlexRow,
        ...CreatePadding(2, 0, 2, 0),
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabs: {
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_SECONDARY}`,
    },
    productContainer: {
        overflow: 'hidden',
        ...CreatePadding(0, 0, 20, 0),
        '& .MuiGrid-spacing-xs-1': {
            width: '100%',
            margin: 0,
            padding: 0,
        },
        margin: '0 auto',
        maxWidth: 450,
        width: '100%',
        [theme.breakpoints.down(480)]: {
            maxWidth: '100%',
        },
    },
    btnLoadmore: {
        cursor: 'pointer',
        width: '100%',
        padding: '20px',
        fontSize: '12px',
        background: '#fff',
        border: 'none',
        color: '#B4B4B4',
    },
    countProductTextDesktop: {
        justifyContent: 'flex-start',
        fontSize: 12,
        marginTop: -25,
    },
    divLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
    },
    hideDivLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
        visibility: 'hidden',
    },
    loadmorePagination: {
        // width: '100%',
        // display: 'flex',
        // justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingBottom: 56,
        '& .MuiPagination-root': {
            '& .MuiPagination-ul': {
                margin: 0,
                display: 'flex',
                padding: 0,
                flexWrap: 'wrap',
                listStyle: 'none',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    },
}));

export default useStyles;
